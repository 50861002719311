import { RouteNames } from "@/types/router";
import { Permissions } from "@/types/permissions";

export const coreRoutes = [
  {
    path: "/insightful",
    name: RouteNames.SOINSIGHTFUL,
    component: () => import("@/views/Insightful/SOInsightful.vue"),
    meta: {
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
      ],
      title: "SO Insightful",
      icon: "home",
    },
  },
  {
    path: "/sales-and-traffic",

    name: RouteNames.SALES_AND_TRAFFIC,
    component: () => import("@/views/SalesAndTraffic/SalesAndTraffic.vue"),
    meta: {
      permission: Permissions.SALES_REPORT,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
      ],
      title: "Sales and Traffic",
      icon: "dollar-sign",
    },
  },
  {
    path: "/product-returns",
    name: RouteNames.PRODUCT_RETURNS,
    meta: {
      permission: Permissions.RETURNS,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
        "productStatus",
      ],
      title: "Product Returns",
      icon: "truck",
    },
    component: () => import("@/views/Returns/ProductReturns.vue"),
  },
  {
    path: "/advertising-summary",
    name: RouteNames.ADVERTISING_SUMMARY,

    meta: {
      permission: Permissions.ADS_SUMMARY,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
      ],
      title: "Advertising Summary",
      icon: "zap",
    },
    component: () => import("@/views/AdsSummary/AdsSummary.vue"),
  },
  {
    path: "/reviews",
    name: RouteNames.REVIEWS,
    meta: {
      permission: Permissions.REVIEW_REPORT,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
      ],
      title: "Ratings and Reviews",
      icon: "message-square",
    },
    component: () => import("@/views/Reviews/ReviewsPage.vue"),
  },
  {
    path: "/brand-protection",
    name: RouteNames.BRAND_PROTECTION,

    meta: {
      permission: Permissions.BRAND_PROTECTION,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
        "searchBySeller",
      ],
      title: "Brand Protection",
      icon: "lock",
    },
    component: () => import("@/views/Protection/BrandProtection.vue"),
  },
  {
    path: "/inventory",
    name: RouteNames.INVENTORY,

    meta: {
      permission: Permissions.INVENTORY,
      filters: ["brands", "channels", "search", "product"],
      title: "Inventory",
      icon: "box",
    },
    component: () => import("@/views/Inventory/AdsInventory.vue"),
  },
  {
    path: "/product-alerts",
    name: RouteNames.ALERTS,

    meta: {
      permission: Permissions.ALERTS,
      filters: ["brands", "channels", "search", "product"],
      title: "Product Alerts",
      icon: "bell",
    },
    component: () => import("@/views/Alerts/ProductAlerts.vue"),
  },

  {
    path: "/product-performance",
    name: RouteNames.PERFORMANCE,

    meta: {
      permission: Permissions.PERFORMANCE,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "product",
        "hideOutOfStock",
        "productStatus",
      ],
      title: "Product Performance",
      icon: "bar-chart",
    },
    component: () => import("@/views/Performance/ProductPerformance.vue"),
  },

  {
    path: "/account-health",
    name: RouteNames.HEALTH,

    meta: {
      permission: Permissions.ACCOUNT_HEALTH,
      filters: [
        "brands",
        "channels",
        "search",
        "picker",
        "kpiPicker",
        "granularity",
        "product",
        "productStatus",
        "amzStatus",
      ],
      title: "Account Health",
      icon: "heart",
    },
    component: () => import("@/views/AccountHealth/AccountHealth.vue"),
  },
  {
    path: "/warehouse",
    name: RouteNames.WAREHOUSE,
    meta: {
      permission: Permissions.WAREHOUSE,
      filters: [
        "brands",
        "search",
        "picker",
        "kpiPicker",
        "granularity:day",
        "product",
      ],
      title: "Warehouse",
      icon: "archive",
    },
    component: () => import("@/views/Warehouse/WarehousePage.vue"),
  },
  {
    path: "/campaigns",
    name: RouteNames.CAMPAIGNS,

    meta: {
      permission: Permissions.ADS_CAMPAIGN,
      filters: [
        "brands",
        "channels",
        "search:custom:Campaigns:Search-by-Name,-ASIN-or-SKU",
      ],
      title: "Campaign Details",
      icon: "edit",
    },
    component: () => import("@/views/Campaign/AdsCampaign.vue"),
  },
  {
    path: "/users",
    name: RouteNames.USERS,
    meta: {
      /**
       * @params [search<@param>:custom<@param>:Users<@constant label>:Search-by-name-or-email<@constant placeholder>]
       */
      // filters: ["search:custom:Users:Search-by-name-or-email"],
      title: "Users List",
      icon: "users",
    },
    component: () => import("@/views/Users/UsersList.vue"),
  },
];

export const additionalRoutes = [
  {
    path: "/users/:id/edit",
    name: RouteNames.EDIT_USER,

    meta: {
      title: "Edit User",
    },
    component: () => import("@/views/Users/UserEditPage.vue"),
  },
];
