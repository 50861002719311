import { defineStore } from "pinia";

import { useStore } from "@/store/main";
import { useToast } from "@/composables/useToast";
import type { PermissionsType } from "@/types/router";
import { useAbortController } from "../abortController.ts";
import { ACCESS_TOKEN } from "@/utils/axios";
import {
  login,
  logout as logoutApi,
  getUser as getUserApi,
  passwordChange as passwordChangeApi,
} from "@/api/auth";
import type { RequestError } from "@/types/requests.ts";

const { error, success } = useToast();

export type AuthUserType = {
  id?: string;
  name?: string;
  email?: string | undefined;
  mustChangePassword?: boolean;
  permissions?: PermissionsType[];
  brandShortNames?: string[];
  role?: "admin" | "client" | "internal";
} | null;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: <string>"",
    isAuth: <boolean>false,
    user: <AuthUserType>null,
    isGetUserLoader: <boolean>false,
    isLoginLoader: <boolean>false,
    isInvalidCred: <boolean>false,
  }),
  actions: {
    async signIn(
      email: string,
      password: string,
      callback?: (status: boolean) => void,
      successLoginCallback?: () => void,
      changePasswordCallback?: () => void,
      accessDeniedCallBack?: () => void,
    ) {
      this.isLoginLoader = true;
      try {
        this.cleanStore();
        localStorage.removeItem(ACCESS_TOKEN);
        const res = await login(email, password);
        this.isInvalidCred = false;
        if (!res?.data?.must_change_password) {
          this.isAuth = true;
          this.token = res?.data?.access_token;
          localStorage.setItem(ACCESS_TOKEN, res?.data?.access_token);

          await this.getUser();
          if (this.user?.role !== "admin" && !this.user?.permissions?.length) {
            accessDeniedCallBack && accessDeniedCallBack();
            return;
          }

          callback && callback(true);
          successLoginCallback && successLoginCallback();
        } else {
          this.user = {
            email: email,
          };
          changePasswordCallback && changePasswordCallback();
        }
      } catch (error: RequestError) {
        const errorData = JSON.parse(error?.message);
        if (errorData.status === 401) this.isInvalidCred = true;
      } finally {
        this.isLoginLoader = false;
      }
    },

    async getUser() {
      if (this.user) return;
      this.isGetUserLoader = true;
      try {
        const res = await getUserApi();
        if (res?.status >= 200 && res?.status < 400) {
          this.user = { ...res.data?.user };
        } else {
          return null;
        }
      } catch (error: RequestError) {
        console.error(error);
        return;
      } finally {
        this.isGetUserLoader = false;
      }
    },
    async logout(callback?: () => void) {
      const store = useStore();
      const abortController = useAbortController();

      this.cleanStore();
      store.clearStore();
      abortController.abortAllRequests();

      try {
        await logoutApi();
        this.isAuth = false;
        this.token = "";
        this.user = null;
        localStorage.removeItem(ACCESS_TOKEN);
        this.cleanStore();
        store.clearStore();
        callback && callback();
      } catch (err) {
        callback && callback();
        console.error(err);
        return;
      }
      store.clearStore();
      this.cleanStore();
    },
    async passwordChange(
      password: string,
      newPassword: string,
      newPasswordConfirmation: string,
      callback: () => void,
      accessDeniedCallBack: () => void,
    ) {
      try {
        this.isInvalidCred = false;
        if (this.user?.email) {
          await passwordChangeApi(
            this.user.email,
            password,
            newPassword,
            newPasswordConfirmation,
          );
          success("Password changed successfully");
          await this.signIn(
            this.user.email,
            newPassword,
            callback,
            undefined,
            undefined,
            accessDeniedCallBack,
          );
        }
      } catch (err) {
        const errorMessage = err as Error;
        const errorData = JSON.parse(errorMessage?.message);
        if (errorData.status === 401) {
          this.isInvalidCred = true;
        }
        error(`Password change failed: ${errorData?.message}`);
        console.error(err);
      }
    },
    cleanStore() {
      this.user = null;
      this.token = "";
      this.isAuth = false;
      this.isInvalidCred = false;
    },
  },
});
