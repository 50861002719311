import { createRouter, createWebHistory } from "vue-router";

import { RouteNames } from "@/types/router";
import { ACCESS_TOKEN } from "@/utils/axios";

import { hasPermission, checkInsightful } from "@/utils/permissions";
import type { PermissionsType } from "@/types/router";
import {
  RoutePathsWithPermissions,
  ArrayOfPriorityRoutes,
} from "@/types/router";
import { useAbortController } from "@/store/abortController";

import { useAuthStore } from "@/store/auth";
import modules from "./modules.js";
import { useFiltersStore } from "@/store/filters";

const router = createRouter({
  history: createWebHistory(),
  routes: modules,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const filterStore = useFiltersStore();
  const abortControlelr = useAbortController();

  // Abort all requests when navigating away from the page
  if (from.path !== to.path && from.path !== "/") {
    abortControlelr.abortAllRequests();
  }

  // Optimize granularity check by moving it outside unnecessary conditions
  if (to.query?.granularity === "DAY" && to.path !== from.path) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { granularity, ...rest } = to.query;
    filterStore.updateGranularityInStore("MONTH");
    return next({
      path: to.path,
      query: rest,
    });
  }

  // Check for auth and redirect if token is missing
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (!to.meta.withoutAuth && !accessToken) {
    return next({ name: "LoginPage" });
  }

  if (
    to.path !== "/login" &&
    to.path !== "/password-forgot" &&
    to.path !== "/reset-password"
  ) {
    await authStore.getUser();
  } else return next();

  if (
    from.path === "/login" &&
    authStore.user?.role !== "admin" &&
    !authStore.user?.permissions?.length
  ) {
    if (to.path === "/login") return next({ name: "DontHavePermission" });
    return next();
  }

  if (authStore?.user?.role === "admin") return next();

  if (
    hasPermission(
      authStore.user?.permissions as string[],
      to.meta?.permission as any,
    ) ||
    !to.name
  ) {
    if (to.path === "/insightful") {
      if (checkInsightful(authStore.user?.permissions as string[])) {
        return next();
      } else {
        const firstFindedPermission = ArrayOfPriorityRoutes.find((route) => {
          return authStore.user?.permissions?.find((permission) => {
            if (permission === route.permission) return route;
          });
        });
        if (!firstFindedPermission?.path)
          return next({ name: RouteNames.DONT_HAVE_PERMISSION });
        return next({
          path: RoutePathsWithPermissions[
            firstFindedPermission?.permission as PermissionsType
          ],
        });
      }
    }
    return next();
  } else {
    next();
  }
});

export default router;
